@import "../../sass/default/color_variable";
.cs-hero.cs-style1 {
  position: relative;
  padding: 70px 0;
  min-height: 850px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  .cs-btn.cs-color1 {
    color: #fff;
  }
  .cs-hero_bg {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    .react-parallax {
      height: 100%;
      width: 100%;
    }
    img {
      object-fit: cover;
      height: 100% !important;
    }
  }
  .cs-dark_overlay {
    position: absolute;
    top: 0;
    left: 0;
    background: #000;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
  .cs-hero_img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 14%;
    z-index: 3;
  }
  .cs-hero_text {
    position: relative;
    z-index: 4;
  }
  .cs-hero_img_sm {
    position: absolute;
    top: 23%;
    right: 0;
    z-index: 1;
    animation: rotate360 30s linear infinite;
  }
  .cs-hero_title {
    color: #fff;
    margin-bottom: 30px;
  }
  .cs-hero_secondary_title {
    color: #fff;
    margin-bottom: 20px;
  }
  .cs-hero_subtitle {
    color: #fff;
    margin-top: 25px;
    margin-bottom: 0;
  }
  &.cs-type1 {
    .cs-hero_img_sm {
      top: 37%;
      right: initial;
      left: -10%;
    }
  }
  &.cs-type2 {
    padding: 80px 0;
    min-height: initial;
    background-attachment: fixed;
    .cs-hero_img {
      position: relative;
      right: initial;
      top: initial;
      transform: initial;
      display: inline-block;
      margin-bottom: 80px;
    }
    .cs-hero_img_sm {
      right: initial;
      left: 29%;
      top: initial;
      bottom: -12%;
    }
    .cs-btn_group {
      justify-content: center;
      margin-top: 40px;
    }
    .cs-hero_secondary_title {
      margin-bottom: 20px;
    }
    .cs-hero_subtitle {
      margin-top: 20px;
    }
  }
  &.cs-type3 {
    min-height: 700px;
    background-position: bottom center;
    background-attachment: fixed;
    .cs-dark_overlay {
      opacity: 0.65;
    }
  }
}
.cs-light .cs-hero.cs-style1 .cs-hero_bg {
  opacity: 1 !important;
}
#background-wrap {
  bottom: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  top: 0;
  opacity: 0.1;
}

/* KEYFRAMES */
@-webkit-keyframes animateBubble {
  0% {
    margin-top: 1000px;
  }
  100% {
    margin-top: -100%;
  }
}
@keyframes animateBubble {
  0% {
    margin-top: 1000px;
  }
  100% {
    margin-top: -100%;
  }
}

@-webkit-keyframes sideWays {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: 50px;
  }
}

@keyframes sideWays {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: 50px;
  }
}

/* ANIMATIONS */
.x1 {
  animation: animateBubble 25s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  left: -5%;
  top: 5%;
  transform: scale(0.6);
}

.x2 {
  animation: animateBubble 20s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  left: 5%;
  top: 80%;
  transform: scale(0.4);
}

.x3 {
  animation: animateBubble 28s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  left: 10%;
  top: 40%;
  transform: scale(0.7);
}

.x4 {
  animation: animateBubble 22s linear infinite,
    sideWays 3s ease-in-out infinite alternate;
  left: 20%;
  top: 0;
  transform: scale(0.3);
}

.x5 {
  animation: animateBubble 29s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  left: 30%;
  top: 50%;
  transform: scale(0.5);
}

.x6 {
  animation: animateBubble 21s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  left: 50%;
  top: 0;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}

.x7 {
  animation: animateBubble 20s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  left: 65%;
  top: 70%;
  transform: scale(0.4);
}

.x8 {
  animation: animateBubble 22s linear infinite,
    sideWays 3s ease-in-out infinite alternate;
  left: 80%;
  top: 10%;
  transform: scale(0.3);
}

.x9 {
  animation: animateBubble 29s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  left: 90%;
  top: 50%;
  transform: scale(0.6);
}

.x10 {
  animation: animateBubble 26s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  left: 80%;
  top: 80%;
  transform: scale(0.3);
}

/* OBJECTS */
.bubble {
  border-radius: 50%;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px white;
  height: 200px;
  position: absolute;
  width: 200px;
}

.bubble:after {
  background: radial-gradient(
    ellipse at center,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 0) 70%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80ffffff', endColorstr='#00ffffff', GradientType=1);
  border-radius: 50%;
  box-shadow: inset 0 20px 30px rgba(255, 255, 255, 0.3);
  content: "";
  height: 180px;
  left: 10px;
  position: absolute;
  width: 180px;
}

@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 1540px) {
  .cs-hero.cs-style1 {
    min-height: 785px;
  }
}
@media screen and (max-width: 1399px) {
  .cs-hero.cs-style1 .cs-hero_img {
    right: 4%;
  }
}
@media screen and (max-width: 1366px) {
  .cs-hero.cs-style1 {
    min-height: 688px;
  }
}
@media screen and (max-width: 1280px) {
  .cs-hero.cs-style1 {
    min-height: 720px;
  }
}
@media screen and (max-width: 1199px) {
  .cs-hero.cs-style1 {
    .cs-hero_img {
      right: 2%;
      max-width: 420px;
    }
    .cs-hero_img_sm {
      max-width: 120px;
    }
  }
}
@media screen and (max-width: 991px) {
  .cs-hero.cs-style1 .cs-hero_img {
    margin-top: 30px;
    position: relative;
    display: inline-block;
    right: initial;
    left: 50%;
    transform: translateX(-50%);
  }
  .cs-hero.cs-style1 {
    .cs-hero_text {
      text-align: center;
    }
    .cs-btn_group {
      justify-content: center;
    }
  }
  .cs-hero.cs-style1.cs-type2 {
    .cs-hero_img {
      left: initial;
      margin-top: 0;
    }
  }
  .cs-hero.cs-style1.cs-type3 {
    min-height: initial;
    padding: 80px 0;
  }
  .cs-hero.cs-style1.cs-type1 .cs-hero_img_sm {
    left: 0;
  }
}

/* ##### Accordians CSS ##### */
.HomeDemo1 {
  max-width: 450px;
  margin-top: -400px;
  z-index: 99;
  margin-left: 700px;
}
.HomeDemo1 .conuter-header h3 {
  font-weight: 600;
  font-size: 24px;
  color: #fff;
}

.HomeDemo1 .ico-counter {
  background-image: linear-gradient(to right, #21d397 0%, #7450fe 100%);
  padding: 40px;
  border-radius: 20px;
}

.HomeDemo1 .count-down .table-cell {
  position: relative;
  width: 25%;
}
.HomeDemo1 .count-down .tab-val {
  width: 90%;
  font-size: 20px;
  font-weight: 500;
  height: 50px;
  line-height: 50px;
  margin: 0 auto;
  background-color: #290571;
  color: #ffffff;
}
.HomeDemo1 .count-down .tab-metr {
  margin-top: 4px;
  font-size: 11px;
  margin-bottom: 15px;
  color: #ffffff;
}
@media (max-width: 480px) {
  .HomeDemo1 .count-down .tab-metr {
    font-size: 14px;
  }
}

.HomeDemo1 .slideCountdown {
  display: flex !important;
  font-family: Courier;
  font-weight: bold;
  justify-content: center;
  color: #fff;
}

.HomeDemo1 .slide-block-time {
  font-size: 30px;
  min-width: 24%;
  margin: 0;
  float: left;
  font-size: 25px !important;
  width: 30px !important;
  margin: 0px !important;
  &:nth-child(2) {
    margin: 0 10px !important;
  }

  &:nth-child(3) {
    margin-right: 10px !important;
  }
}

.HomeDemo1 .wrap-stage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #290571;
  padding: 20px;
}

.HomeDemo1 .slide-block-time .wrap-stage > .stage {
  width: 20px;
  overflow: hidden;
  height: 40px;
  position: relative;
}

.HomeDemo1 .slide-block-time > .title {
  font-size: 10px;
  font-weight: normal;
  margin-top: 5px;
  text-transform: lowercase;
  // color: transparent;
}

.HomeDemo1 .ico-counter a {
  text-decoration: none !important;
}

.HomeDemo1 .ico-progress span {
  color: #370779;
  font-size: 12px;
  font-weight: 700;
  padding-top: 7px;
  display: inline-block;
}

.HomeDemo1 .ico-progress .current-progress {
  width: 100%;
  height: 20px;
  position: relative;
  background: rgba(191, 191, 191, 0.6);
  border-radius: 7px;
}

.HomeDemo1 .current-progress::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 26px;
  bottom: -5px;
  left: 12%;
  background: #fff;
}

.HomeDemo1 .list-percent {
  margin-top: 1rem;
  margin-bottom: 0;
}

.HomeDemo1 .ico-progress li {
  font-size: 18px;
  font-weight: 400;
}

.HomeDemo1 .ico-progress li.title {
  float: left;
  padding-left: 30px;
  font-weight: 500;
  color: #fff;
}

.HomeDemo1 .ico-progress li.strength {
  float: right;
  font-weight: 500;
  color: #fff;
}

.HomeDemo1 .clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.HomeDemo1 .doc-element {
  background-color: #1d025c;
  border-radius: 4px;
  border-bottom: 2px solid #25cbd3;
  position: relative;
  transition: 0.5s;
  cursor: pointer;
  padding: 20px;
  margin-top: 20px;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.HomeDemo1 .doc-element .document-entry .title {
  font-size: 14px;
  color: #fff;
  line-height: 1.35;
}
.HomeDemo1 .doc-element:hover {
  background-color: #25cbd3;
}
.HomeDemo1 .doc-element:hover:after {
  opacity: 1;
}

.HomeDemo1 .token-distribution h2 {
  color: #fff;
}

.HomeDemo1 .our-mission-content h6 {
  color: #fff;
}
.HomeDemo1 .button {
  position: relative;
  z-index: 0;
  overflow: hidden;
  display: inline-block;
  padding: 14px 40px;
  font-size: 16px;
  z-index: 2;
  line-height: 1.25;
  color: #25cbd3 !important;
  background: #fff;
  border: 0 solid;
  border-radius: 40px;
  font-weight: 600;
  letter-spacing: 0.02em;
  text-transform: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  user-select: none;
  transition: 250ms all ease-in-out;
  box-shadow: 0 3px 21px 4px rgba(41, 41, 58, 0.2);
}
.HomeDemo1 .button:hover {
  background: #25cbd3;
  color: #fff !important;
}
.HomeDemo1 .button i {
  margin-right: 10px;
}
