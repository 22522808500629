@import "../../sass/default/color_variable";
.cs-image_box.cs-style1 {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 300px;
  border: 1px solid rgba($accent, 0.1);
  padding: 20px 15px 5px;
  position: relative;
  margin-top: 5px;
  &::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    left: -5px;
    top: -5px;
    background-color: transparent;
    transition: all 0.3s ease;
  }
  img {
    max-height: 100%;
  }
  &:hover {
    border-color: rgba($accent, 0.2);
    &::before {
      background-color: rgba($accent, 0.2);
      left: 0;
      top: 0;
    }
  }
}

.cs-image_box.cs-style2 {
  display: block;
  background: linear-gradient(108.18deg, #EA4C89 -2.02%, #4D44C6 100%);
  padding: 1px;
  border-radius: 10px;
  img {
    max-height: 100%;
  }
  .cs-image_box_in {
    border-radius: inherit;
    padding: 10px;
    background-color: #fff;
  }
  .cs-image_box_img {
    background: #E94875;
    border-radius: inherit;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 290px;
  }
}

@media screen and (max-width: 575px) {
  .cs-image_box.cs-style1 {
    height: 200px;
    padding: 20px 10px 5px;
  }
  .cs-image_box.cs-style2 {
    .cs-image_box_img {
      height: 190px;
    }
  }
}