.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.heading {
  font-size: 45px;
}
.highlight {
  color: #0052ff;
}
.countdown-wrapper {
  max-width: 800px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}
.countdown-box {
  background-color: #0052FF;
  font-size: 40px;
  font-weight: 700;
  color: #EEB700;
  border-radius: 15px;
  width: 120px;
  height: 120px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.legend {
  font-size: 24px;
  color: #a6b1e1;
}
.wish-message {
  font-size: 45px;
  font-weight: 700;
}
.birthdate {
  font-size: 25px;
  font-weight: 600;
  color: #0052ff;
}
.credits {
  margin-top: 15px;
}
.github-logo {
  opacity: 0.5;
  width: 50px;
}
.github-logo:hover {
  opacity: 1;
}
.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 20px;
}

.buttonsec{
    margin-top: 20px;
    z-index: 99;
}