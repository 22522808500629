/* Sayfa arka planı için lacivert-mor-siyah gradyan */
.cs-mint_section {
    background: linear-gradient(145deg, #000000, #4b0082, #000080);
    color: #fff;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  /* Metinler için lacivert-mor-siyah gradyan */
  .cs-m0.text-center, .cs-list_left {
    background: linear-gradient(to right, blue, white);
    -webkit-background-clip: text;
    color: transparent;
    font-weight: bold;
  }
  
  /* Price değeri için stil */
  .cs-list_right {
    color: #ffd700; /* Eğer altın sarısı rengi korumak istiyorsanız bu kısmı değiştirmeyin. */
  }
  
  /* Buy Now butonu için stil */
  .cs-btn.cs-btn_filled.cs-accent_btn.cs-buy-now-btn {
    background-color: #4b0082; /* Mor renk */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    padding: 10px 20px;
  }
  
  /* Buy Now butonu üzerine gelince renk değişimi */
  .cs-btn.cs-btn_filled.cs-accent_btn.cs-buy-now-btn:hover {
    background-color: #000080; /* Lacivert renk */
  }
  
  /* Quantity input alanı için stil */
  .cs-form_field_wrap {
    display: flex;
    justify-content: flex-end;
  }
  
  .cs-form_field {
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  /* Quantity ve Total Price için liste stil ayarları */
  .cs-list.cs-style2 {
    list-style: none;
    padding: 0;
  }
  
  .cs-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .cs-button-spacing {
    margin-top: 20px;
  }
  